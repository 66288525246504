<div class="navbar-container  border-r" fxLayout="column">
  <!-- <div fxLayout="column" fxLayoutGap="16px" class="side-nav border" fxLayoutAlign="start center"> -->
  <div *ngFor="let scope of scopes;let i = index">
    <!-- <button mat-icon-button matTooltip="{{getTooltipForScope(scope)}}"
        [routerLink]="getrouterLinkForScope(scope, role)">
        <mat-icon class="material-icons-outlined">{{getMatIconNameForScope(scope)}}</mat-icon>
      </button> -->
    <div class="menu-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
         [ngClass]="{'active': selectedIndex === i}" (click)="setSelectedMenu(scope, role, i)">
      <mat-icon>{{getMatIconNameForScope(scope)}}</mat-icon>
      <div class="mat-body-1 menu-item-name">{{getTooltipForScope(scope)}}</div>
    </div>
    <mat-divider></mat-divider>

  </div>
  <!-- </div> -->
</div>

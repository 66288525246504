import { Pipe, PipeTransform } from "@angular/core";
import { ComponentNames } from "../medex-services/medex-readings.model";



@Pipe({
    name: "EmrComponentsDisplayName",
    pure: true
})
export class EmrComponentsDisplayNamePipe implements PipeTransform {

    transform(componentName: string) {
        switch (componentName){
            case ComponentNames.NOTES_AND_ATTACHMENTS:
                return "Notes and Attachments";
            default:
                return componentName;
        }
    }
}
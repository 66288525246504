import { Component, OnInit } from '@angular/core';
import { MedeAuthService } from 'src/app/mede-auth/mede-auth.service';
//@ts-ignore
import packageInfo from '../../../../package.json';
@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent implements OnInit {
  isAccount?: boolean = false;
  public appVersion: string = packageInfo.version;
  constructor(
    public medeAuth: MedeAuthService,
  ) { }

  ngOnInit(): void {
  }

  getProfilePicture(emailHash: any) {
    return `https://www.gravatar.com/avatar/${emailHash}?d=identicon`
  }
}

import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    UrlTree, CanLoad, Route, UrlSegment, ActivatedRoute
} from "@angular/router";
import { Observable } from "rxjs";
import { MedeAuthService } from "./mede-auth.service";
import { filter, map, tap } from "rxjs/operators";
import { APP_SCOPES } from "../scopes";

@Injectable({
    providedIn: "root"
})
export class MedexDefaultScopeRedirect implements CanActivate {

    constructor(
        private medeAuth: MedeAuthService,
        private router: Router
    ) {
    }

    routeBasedOnScope(scopes){

        if (scopes.includes(APP_SCOPES.PROHEALTH)) {
            console.log("Redirecting based on scope ", APP_SCOPES.PROHEALTH);
            this.router.navigate(["prohealth"]);
            return;
        }
        console.log("Invalid Scope. Logging out ");
        this.medeAuth.logout();
        return;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("Role Guard activated ");
        return this.medeAuth.userProfile$.pipe(
            tap(userProfile => console.log("User Profile in Role Gaurd ", userProfile)),
            filter(userProfile => userProfile !== null),
            map(userProfile => {

                const scopes = userProfile.scopes || [];

                if (scopes.length > 0) {
                    console.log("Redirecting based on scope ", scopes);
                    this.routeBasedOnScope(scopes);
                    return true;
                } else {
                    console.log("No Scopes found. Logging out ");
                    this.medeAuth.logout();
                    return true;
                }
            }),
        );
    }
}

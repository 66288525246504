import { Pipe, PipeTransform } from "@angular/core";

/**
 * Map to Iteratble Pipe
 *
 * It accepts Objects and Maps and returns an Array. The Key is expected to be in the Object of the Array
 *
 * Example:
 *
 *  <div *ngFor="#value of Map | mapToIterable">
 *    value {{value}}
 *  </div>
 *
 */
@Pipe({
  name: "mapToIterable",
  pure: false
})
export class MapToIterablePipe implements PipeTransform {

  transform(object) {
    const result = [];

    if (object) {
      if (object?.entries) {                            // The Object is a Map
        object.forEach((value, key) => {
          result.push(value);
        });
      } else {                                        // The Object is a normal Javascript Object
        for (const key in object) {
          if (object.hasOwnProperty(key)) { result.push(object[key]); }
        }
      }
    }
    return result;
  }
}

@Pipe({
  name: "numberZeroToDash"
})
export class NumberZeroToDashPipe implements PipeTransform {

  transform(value){
    if (value == 0 || value == null || !value){
      return "-";
    }

    else if (!value){
      return "-";
    }
    else { return value; }
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByThree',
  pure: true
})
export class GroupByThreePipe implements PipeTransform {

  transform(arrayOfItems) {
    if (arrayOfItems && arrayOfItems.length) {
      let group = [];

      for (let i = 0, j = 0; i < arrayOfItems.length; i++) {
        if (i >= 3 && i % 3 === 0)
          j++;
        group[j] = group[j] || [];
        group[j].push(arrayOfItems[i])
      }

      return group;
    } else {
      return arrayOfItems;
    }
  }
}

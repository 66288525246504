import {VitaAlert} from './vita-alerts.service';

export class CarePlanEvent {
  title: string;
  message: string;
  timestamp: number;
  metric: string;
  type: string;
  actionBy: string;
  alert: VitaAlert;
}

export class VitaCareplanMetric {
  static Activity = "activity";
  static BloodGlucose = "bloodglucose";
  static BloodGlucoseFasting = "bloodglucosefasting";
  static BloodGlucosePostPrandial = "bloodglucosepostprandial";
  static BloodPressureSystolic = "bloodpressuresystolic";
  static BloodPressureDiastolic = "bloodpressurediastolic";
  static BloodPressure = "bloodpressure";
  static Body = "body";
  static HbA1c = "hba1c";
  static Medication = "medication";
  static Insulin = "insulin";
  static Report = "report";
  static METRIC_STRESS = "stress";
  static METRIC_DIET = "diet";
  static Others = "others";
  static lipid ="lipid";
  static thyroid="thyroid"
  static spo2 ="spo2"
}



import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MedeAuthService } from "../../mede-auth/mede-auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
// @ts-ignore
import packageInfo from '../../../../package.json';
import { environment } from "../../../environments/environment";
import { APP_SCOPES } from "src/app/scopes";
import { MedeReadingsService } from "src/app/medex-services/mede-readings.service";

@Component({
    selector: "app-top-nav",
    templateUrl: "./top-nav.component.html",
    styleUrls: ["./top-nav.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TopNavComponent implements OnInit {

    @Output() toggleApps = new EventEmitter<boolean>();

    public appVersion: string = packageInfo.version;

    userProfile: any;
    role: any;
    profileJson: string = null;
    navigationLinks: any = [{
        path: "doctor",
        label: "Today"
    }, {
        path: "doctor",
        label: "History"
    }];
    todos = [];
    text;
    links = [];
    activeLink = "Consult";
    facility: any;
    eccLogin: boolean = false;
    headerLogo1: string = "";

    constructor(
        public medeAuth: MedeAuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private medeReadingsService: MedeReadingsService
    ) {

    }

    ngOnInit() {
        this.medeAuth.userProfile$.pipe(
            tap((profile) => {
                console.log("User profile Obtained in top nav", profile);
                this.userProfile = profile;
                this.profileJson = JSON.stringify(profile, null, 2)
                if (this.userProfile) {
                    const roles = this.userProfile["https://medeintegra.app/roles"];
                    this.role = roles[0];
                    console.log("role is", this.role)
                    this.facility = profile?.facility

                    if (this.userProfile?.azp === "d8b0646b-8ad0-42d5-bf45-37c81cb4a53c" || this.userProfile?.azp === "6bc9309a-75f7-4084-a3a3-0f6539c0f66a") {
                        this.eccLogin = true;
                    }
                }
            }),
            // switchMap(() => this.medeReadingsService.getPreferenceByPreferenceTypeAndSourceId("PROJECT_LOGOS", this.userProfile?.azp))
        ).subscribe(preferenceInfo => {
            // console.log("preferenceInfo", preferenceInfo,preferenceInfo.preference?.preferenceInfo?.headerlogo1)

            if (preferenceInfo && preferenceInfo.code == 1) {
                this.headerLogo1 = preferenceInfo.preference?.preferenceInfo?.headerlogo1
            }
        })

        this.medeAuth.getAppScopes().subscribe(
            (app_scopes) => {
                console.log("App Scopes Obtained ", app_scopes);
                this.links = [];
                if (app_scopes.indexOf("consult") > -1) this.links.push("Consult");
            },
            console.error
        )
    }

    addNotice() {
        this.todos.push();
        // // @ts-ignore
        // this.noticeService.addNotice();
    }


    updateNavigation() {

    }


    navigateToDoctorProfile() {
        this.router.navigate(["profile"], { relativeTo: this.activatedRoute })
    }

    openAppointment(appointmentId: any) {
        console.log("Navigating to ", appointmentId);
        if (appointmentId) {
            this.router.navigate(["appointment", appointmentId])
        }
    }

    // openPharmacy() {
    //     window.location.replace(environment.medexApps.pharmacy);
    // }

    navigateToApp(link: string) {
        // switch (link) {
        //     case "Consult":
        //         return window.location.replace(environment.medexApps.consult);
        //     case "Pharmacy":
        //         return window.location.replace(environment.medexApps.pharmacy);
        //     case "Lab":
        //         return window.location.replace(environment.medexApps.lab);
        // }
    }

    getProfilePicture(emailHash: any) {
        return `https://www.gravatar.com/avatar/${emailHash}?d=identicon`
    }

    openAppSelector() {
        this.toggleApps.next(true)
    }

    getLogoSrc() {
        return this.headerLogo1 ? this.headerLogo1 : environment.studio.logo;
    }

}

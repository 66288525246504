import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    DateToEpochPipe,
    EpochToAgePipe,
    EpochToCalendar,
    EpochToDatePipe,
    EpochToDay,
    EpochToLLFormat,
    EpochToMonth,
    EpochToTime,
    TimeOfDayToTime,
    SecondsToHoursAndMinutes,
    EpochToLLLFormat,
    FreeText,
    EpochToWeeksAndDaysPipe,
    EpochToTimeWithoutMeridian,
    EpochToMeridian,
    DateToCalendar,
    SecondsToMinutesAndSeconds,
    EpochToDateMonthYear,
    EpochToDateFromNow,
    TaskTimingToTime, EpochToNowPipe, EpochToAgeDetsPipe
} from "./epoch-pipes.pipe";

import {SafePipe} from "./safe.pipe";
import { MedexMaterialModule } from "../app-ui.module";
import {GroupByThreePipe} from "./group-by.pipe";
import {
    DayOfMonth, MetricIdToNamePipe, MetricIdToReadableNamePipe,
    NameCasePipe,
    NumberOrdinalPipe,
    StringToArrayToPipedList,
    StringToComparatorPipe,
    UserReadableString
} from "./string-pipes";
import {VitaUserToGender, VitaUserToNamePipe} from "./vitauser.pipe";
import {MapToIterablePipe, NumberZeroToDashPipe} from "./map-to-iterable.pipe";
import { DatePipe } from "./date.pipe";
import {EncounterEventDisplayNamePipe, EncounterServiceTypeDisplayNamePipe} from "./encounters.utility";
import { EmrComponentsDisplayNamePipe } from "./emr-components.pipe";


@NgModule({
    imports: [
        CommonModule,
        MedexMaterialModule
    ],
    declarations: [
        DateToEpochPipe,
        EpochToDatePipe,
        EpochToDay,
        EpochToMonth,
        EpochToCalendar,
        EpochToLLFormat,
        EpochToLLLFormat,
        TimeOfDayToTime,
        SecondsToHoursAndMinutes,
        SecondsToMinutesAndSeconds,
        EpochToAgePipe,
        EpochToAgeDetsPipe,
        EpochToNowPipe,
        EpochToDateFromNow,
        EpochToDateMonthYear,
        EpochToTime,
        EpochToTimeWithoutMeridian,
        EpochToMeridian,

        DateToCalendar,

        SafePipe,
        FreeText,
        EpochToWeeksAndDaysPipe,

        GroupByThreePipe,
        NameCasePipe,
        NumberOrdinalPipe,
        StringToComparatorPipe,
        StringToArrayToPipedList,
        DayOfMonth,
        SafePipe,
        MetricIdToNamePipe,
        MetricIdToReadableNamePipe,

        VitaUserToNamePipe,
        VitaUserToGender,

        MapToIterablePipe,
        NumberZeroToDashPipe,
        TaskTimingToTime,
        UserReadableString,
        DatePipe,

        EncounterServiceTypeDisplayNamePipe,
        EncounterEventDisplayNamePipe,
        EmrComponentsDisplayNamePipe
    ],
    exports: [
        DateToEpochPipe,
        EpochToDatePipe,
        EpochToDay,
        EpochToMonth,
        EpochToCalendar,
        EpochToLLFormat,
        EpochToLLLFormat,
        TimeOfDayToTime,
        SecondsToHoursAndMinutes,
        SecondsToMinutesAndSeconds,
        EpochToAgePipe,
        EpochToAgeDetsPipe,
        EpochToNowPipe,
        EpochToDateFromNow,
        EpochToTime,
        EpochToTimeWithoutMeridian,
        EpochToMeridian,
        EpochToDateMonthYear,
        DateToCalendar,

        SafePipe,
        FreeText,
        EpochToWeeksAndDaysPipe,

        GroupByThreePipe,

        NameCasePipe,
        NumberOrdinalPipe,
        StringToComparatorPipe,
        StringToArrayToPipedList,
        DayOfMonth,
        SafePipe,
        MetricIdToNamePipe,
        MetricIdToReadableNamePipe,

        VitaUserToNamePipe,
        VitaUserToGender,

        MapToIterablePipe,
        NumberZeroToDashPipe,
        TaskTimingToTime,
        UserReadableString,
        DatePipe,

        EncounterServiceTypeDisplayNamePipe,
        EncounterEventDisplayNamePipe,
        EmrComponentsDisplayNamePipe
    ]
})
export class MedexUtilitiesModule {
}

import { Component, HostListener, Injector, OnInit } from "@angular/core";
import { ComponentPortal, Portal, PortalInjector } from "@angular/cdk/portal";
import { of } from "rxjs";
import { delay, filter, tap } from "rxjs/operators";
import { MedeAuthService } from "../../mede-auth/mede-auth.service";
import { MedexNetworkConnectionService } from "../medex-network-connection.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    userId: string;
    appointmentDetails: any;
    role: any;
    roleBasedScopes: any;
    isFullScreenMode: boolean = false;
    isSmallScreen: boolean = false;
    constructor(
        private _injector: Injector,
        private authService: MedeAuthService,
        private connectionService: MedexNetworkConnectionService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.authService.userProfile$.pipe(
            filter((profile) => profile?.sub),
            tap((profile) => {
                if (profile) {
                    this.userId = profile?.sub;
                    this.role = profile["https://medeintegra.app/roles"][0];
                    this.roleBasedScopes = profile.scopes;
                    console.log("Dashboard Component role", profile, this.role);
                }
            }),
        ).subscribe(console.log, console.error);

        this.initiateNetworkMonitoring();
    }

    initiateNetworkMonitoring() {
        console.log("Initiating Network Monitoring");
        this.connectionService.createOnline$().subscribe(isOnline => {
            console.log("Network Status Update ", isOnline);
            if (isOnline) {
                this.snackBar.dismiss();
            } else {
                this.snackBar.open("No Internet. Please Check your Connection", null, {
                    duration: 30000,
                    horizontalPosition: "end",
                    verticalPosition: "top",
                });
            }
        });
    }
}

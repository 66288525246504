import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MedexPreference } from "./medex-iam.model";
import { environment } from "../../environments/environment";
import { map, share, tap } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";;

@Injectable({
  providedIn: "root"
})
export class MedexPreferencesService {

  // preferences$ = new BehaviorSubject<any>([]);
  
  // preferencesMaster$ = new BehaviorSubject<any>([]);

  // typedPreference$ = new BehaviorSubject<any>([]);

  constructor(
    private http: HttpClient
  ) {
    // console.log("Preferences Service Initiated ");
  }

  

  // listPreferenceMasters() {
  //   return this.http.get("/assets/data/preference_masters.json").pipe(
  //     map((res: any) => res.preferences),
  //     tap((preferences) => this.preferencesMaster$.next(preferences)),
  //     share()
  //   );
  // }

  // listPreferences() {
  //   const url = environment.medexIam.url + environment.medexIam.preferences.get;
  //   return this.http.get(url).pipe(
  //     tap((res) => console.log("res for ", url, res)),
  //     // map((res: any) => this.dummyPreferences),
  //     map((res: any) => res.preferences),
  //     tap((preferences) => this.preferences$.next(preferences)),
  //     share()
  //   );
  // }

  

  // isEnabled(preferenceType: string): Observable<boolean> {
  //    return this.preferences$.value.find((p) => p.preferenceType === preferenceType)?.isEnabled;
  // }

  // getPreference(preferenceType: string): MedexPreference {
  //   return this.preferences$.value.find((p) => p.preferenceType === preferenceType);
  // }

  // getPreferenceByType(preferenceType: string){
  //   this.preferences$.asObservable().subscribe(preferences=>{
  //     if(preferences){
  //       const preference:any = preferences.find((p) => p.preferenceType === preferenceType);
  //       this.typedPreference$.next(preference);
  //     }
  //   });
  //   return this.typedPreference$.asObservable();
  // }

  // addPreference(preference: MedexPreference) {
  //   const url = environment.medexIam.url + environment.medexIam.preferences.add;
  //   return this.http.post(url, preference).pipe(
  //     tap((res) => console.log("res for ", url, res))
  //   );
  // }



  // getPreferenceId(sourceId, sourceType, preferenceType) {
  //   console.log({sourceId, sourceType, preferenceType})
  //   return (this.preferences$.value.find((pref) => pref?.sourceId === sourceId && pref?.sourceType === sourceType && pref?.preferenceType === preferenceType))?.preferenceId
  // }
}

import { State, Action, Selector, StateContext } from '@ngxs/store';
import { ProgramName, UserVitaId, ProviderName, RefreshRoutineInfo, ProviderId, UserEnrollmentDate } from './vita-call-actions';
import {Injectable} from "@angular/core";

export interface VitaCallStateModel {
    programName: string;
    callTypes: string[];
    reportMetrics: string[];
    vitaId: string;
    providerName: string;
    reloadRoutineInfo: boolean;
    providerId: string;
    userEnrollmentDate: any;
}

@State<VitaCallStateModel> ({
    name: 'vitaCall',
    defaults: {
        programName: '',
        callTypes: [],
        reportMetrics: [],
        vitaId: '',
        providerName: '',
        reloadRoutineInfo: false,
        providerId: '',
        userEnrollmentDate: 0
    }
})
@Injectable()
export class VitaCallState {

    @Selector()
    public static programType(state: VitaCallStateModel) {
        return state.programName;
    }


    @Selector()
    public static callType(state: VitaCallStateModel) {
        return state.callTypes;
    }

    @Selector()
    public static reportMetrics(state: VitaCallStateModel) {
        return state.reportMetrics;
    }

    @Selector()
    public static getVitaId(state: VitaCallStateModel) {
        return state.vitaId;
    }

    @Selector()
    public static getProviderId(state: VitaCallStateModel) {
        return state.providerId;
    }

    @Selector()
    public static getProviderName(state: VitaCallStateModel) {
        return state.providerName;
    }

    @Selector()
    public static getReloadStatus(state: VitaCallStateModel) {
        return state.reloadRoutineInfo;
    }

    @Selector()
    public static getUserEnrollmentDate(state: VitaCallStateModel) {
        return state.userEnrollmentDate;
    }

    @Action(ProgramName)
    public routineData(ctx: StateContext<VitaCallStateModel>, {payload}: ProgramName) {
        const state = ctx.getState();
        console.log('program name in action', payload);
        state.programName = payload;


        if (payload.toString() === "ahc") {
            state.callTypes = ["Ad-Hoc Call", "Monthly Call"];
            state.reportMetrics = ["Blood Glucose", "HbA1c", "Lipid", "Thyroid", "Others"];
            ctx.patchState(state);
        }

        if (payload.toString() === "healthy_motherhood") {
            state.callTypes = ["First Call", "Diet Call", "Infant Diet Call", "AdHoc Call", "Last Call", "Post Natal Call", "Psychology Call"];
            state.reportMetrics = ["Blood Sugar Tests", "Glucose Tolerance Test", "Thyroid profile", "Dual Marker Test", "Triple Marker Test", "Quadruple Marker Test", "Amniotic Fluid Index", "CTG", "Others"];
            ctx.patchState(state);
        }

        if (payload.toString() === "apollo_24_7") {
            state.callTypes = ["Ad-Hoc Call", "Monthly Call"];
            state.reportMetrics = ["Blood Glucose", "HbA1c", "Lipid", "Thyroid", "Others"];
            ctx.patchState(state);
        }

        if (payload.toString() === "apollo_sugar") {
            state.callTypes = ["Ad-Hoc Call", "Monthly Call"];
            state.reportMetrics = ["Blood Glucose", "HbA1c", "Lipid", "Thyroid", "Others"];
            ctx.patchState(state);
        }


        console.log('after ptach state in action', ctx.getState());
    }

    @Action(UserVitaId)
    public userVitaId(ctx: StateContext<VitaCallStateModel>, {payload}: UserVitaId) {
        const state = ctx.getState();
        if (payload) {
            state.vitaId = payload;
            ctx.patchState(state);
        }
        console.log('vitaid in state', ctx.getState());
    }

    @Action(ProviderName)
    public providerName(ctx: StateContext<VitaCallStateModel>, {payload}: ProviderName) {
        const state = ctx.getState();
        if (payload) {
            state.providerName = payload;
            ctx.patchState(state);
        }
        console.log('provider Name in state', ctx.getState());
    }

    @Action(ProviderId)
    public providerId(ctx: StateContext<VitaCallStateModel>, {payload}: ProviderId) {
        const state = ctx.getState();
        if (payload) {
            state.providerId = payload;
            ctx.patchState(state);
        }
        console.log('provider id in state', ctx.getState());
    }

    @Action(UserEnrollmentDate)
    public userEnrollmentDateAction(ctx: StateContext<VitaCallStateModel>, {payload}: UserEnrollmentDate) {
        const state = ctx.getState();
        if (payload) {
            state.userEnrollmentDate = payload;
            ctx.patchState(state);
        }
        console.log('userEnrollmentDate in state', ctx.getState());
    }

    @Action(RefreshRoutineInfo)
    public refreshRoutine(ctx: StateContext<VitaCallStateModel>, {payload}: RefreshRoutineInfo) {
        const state = ctx.getState();
        if (payload) {
            state.reloadRoutineInfo = payload;
            ctx.patchState(state);
        }
        console.log('reload routine info in state', ctx.getState());
    }

}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MedexMaterialModule } from "./app-ui.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { HttpClientModule } from "@angular/common/http";
import { AppCommonsModule } from "./app-commons/app-commons.module";
import { environment } from "../environments/environment";
import { AsyncPipe } from "@angular/common";

import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { PinchZoomModule } from "ngx-pinch-zoom";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFirestore, AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {NgxEchartsModule} from "ngx-echarts";
import {MedexUtilitiesModule} from "./medex-utilities/medex-utilities.module";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { NgxBarcodeModule } from "ngx-barcode";
import { LsPlotterModule } from "ls-plotter";

import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from "@angular/fire/compat/analytics";
import { ServiceWorkerModule } from "@angular/service-worker";
import {MatSidenavModule} from "@angular/material/sidenav";
// import { GraphQLModule } from './graphql.module';

@NgModule({
    declarations: [
        AppComponent,
     ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MedexMaterialModule,
        HttpClientModule,
        AppCommonsModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import("echarts"), // or import('./path-to-my-custom-echarts')
        }),
        CKEditorModule,
        PinchZoomModule,
        MedexUtilitiesModule,

        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        NgxBarcodeModule,

        LsPlotterModule,
         ServiceWorkerModule.register("ngsw-worker.js", {
           enabled: environment.production,
           // Register the ServiceWorker as soon as the application is stable
           // or after 30 seconds (whichever comes first).
           registrationStrategy: "registerWhenStable:30000"
         }),
        //  GraphQLModule // ECG FROM LIFESIGNALS

    ],
    providers: [
        AsyncPipe,
        AngularFirestore,
        UserTrackingService,
        ScreenTrackingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export class ConsultationMode {
    static PHYSICAL = "PHYSICAL";
    static VIRTUAL = "VIRTUAL";
}

export class EncounterClass {
    static AMB = "AMB";
    static EMER = "EMER";
    static FLD = "FLD";
    static HH = "HH";
    static IMP = "IMP";
    static ACUTE = "ACUTE";
    static NONAC = "NONAC";
    static OBSENC = "OBSENC";
    static PRENC = "PRENC";
    static SS = "SS";
    static VR = "VR";
}

export class EncounterType {
    static AHC = "AHC";
    static PHC = "PHC";
}

export class EncounterServiceType {
    static GP_CONSULTATION = "GP_CONSULTATION";

    static OPHTHAL_CONSULTATION = "OPHTHAL_CONSULTATION";
    static DENTAL_CONSULTATION = "DENTAL_CONSULTATION";
    static ENDOCRINOLOGY_CONSULTATION = "ENDOCRINOLOGY_CONSULTATION";
    static CARDIOLOGY_CONSULTATION = "CARDIOLOGY_CONSULTATION";

    static VITALS_SCREENING = "VITALS_SCREENING";

    static PRE_EMPLOYMENT_MER = "PRE_EMPLOYMENT_MER";
    static NCD_SCREENING = "NCD_SCREENING";
    static WELLNESS_CAMP = "WELLNESS_CAMP";
    static LAB_RESULT_AUTHENTICATION = "LAB_RESULT_AUTHENTICATION";
    static RADIOLOGY_RESULT_AUTHENTICATION = "RADIOLOGY_RESULT_AUTHENTICATION";

    static PRESCRIPTION_VALIDATION = "PRESCRIPTION_VALIDATION";

    static NURSING = "NURSING";
    static PHYSIOTHERAPY = "PHYSIOTHERAPY";

    static PROHEALTH_CHECK = "PROHEALTH_CHECK";

    static LAB_SAMPLE_COLLECTION = "LAB_SAMPLE_COLLECTION";

    static FKHP_PRESCRIPTION_VALIDATION = "FKHP_PRESCRIPTION_VALIDATION";

    // AHC
    static AHC_PHYSIO_ASSESSMENT = "AHC_PHYSIO_ASSESSMENT";
    static AHC_PRESCRIPTION_VALIDATION = "AHC_PRESCRIPTION_VALIDATION";

    static AHC_DOCTOR_VC = "AHC_DOCTOR_VC";
    static AHC_DOCTOR_AT_HOME = "AHC_DOCTOR_AT_HOME";
    static AHC_NURSE_AT_HOME = "AHC_NURSE_AT_HOME";
    static AHC_PHYSIO_AT_HOME = "AHC_PHYSIO_AT_HOME";
    static PH_HEALTH_CHECK = "PH_HEALTH_CHECK";
    static AHLL_LAB_TEST_SAMPLE_COLLECTION = "AHLL_LAB_TEST_SAMPLE_COLLECTION";
}

class VideoRoomInfo {
    roomId: string;
    patientRoomUrl: string;
    doctorRoomUrl: string;
}

class PreferenceType {
    static GENDER = "GENDER";
    static LANGUAGE = "LANGUAGE";
    static PERIOD = "PERIOD";
}

class MedexPreference {
    dataId?: string;
    type: PreferenceType;
    data: any;
}

export class MedexEncounter {
    // procedure model to be added for offline doctor consultation

    // care plan model to be added for nurse flow

    // patient documents - submission

    encounterId?: string;
    encounterDisplayId?: string;
    encounterReferenceId?: string;

    class?: string;
    type?: EncounterType;
    serviceType?: EncounterServiceType;

    status?: EncounterStatus;

    carePlanId?: string;

    patientInfo?: MedePatient;
    reasonForConsultation?: string;
    // TODO : Add Encounter Facility

    // TODO How to Accomodate Care Team
    providerInfo?: any;

    // TODO Need to be updated with - Communication Channels for Consultation
    consultationMode?: ConsultationMode;
    videoRoomInfo?: VideoRoomInfo;

    preferences?: MedexPreference[];
    preferredPeriod: {
        startEpoch?: number;
        endEpoch?: number;
    };
    period?: {
        startEpoch?: number;
        endEpoch?: number;
    };

    webhookUrl?: string; // when status updates happen

    // TODO : Check if the key name can be improved
    retryCount?: number;

    // TODO : Check if these are required
    assignedDoctorIds?: string[];
    isSelectedDoctor?: boolean;

    // TODO : Check if can be moved to reports collection
    prescription?: MedePrescription;

    // TODO : Check if this can be removed - Check if it can be moved to Orders / Bookings Collection
    isPaymentRequired?: boolean;
    paymentInfo?: MedePaymentInfo;

    metaInfo?: any;
    changeLog?: ChangeLog[];
}

class MedePaymentInfo {
    orderId?: string;
    amountPaid?: number; // in paise
    razorpayOrderId?: string;
}

class MedePrescription {
    prescriptionUrl?: string;
}

class ChangeLog {
    updatedAt?: number;
    updatedBy?: string;
    azp?: string;
    status?: EncounterStatus;
    data?: any;
}

export class DoctorSlotTime {
    from: number;
    to: number;
}

export class PatientSlotTime {
    from: number;
    to: number;
}

export class EncounterLifecycleEvent {
    static CREATE = "create";
    static ASSIGN_CARE_PROVIDER = "assign_care_provider";
    static ACCEPT = "accept";
    static DECLINE = "decline";
    static REJECT = "reject";
    static COMPLETE = "complete";
    static CANCEL = "cancel";
}

export class EncounterStatus {
    static CREATED = "CREATED";
    static AWAITED = "AWAITED";
    static CONFIRMED = "CONFIRMED";
    static DECLINED = "DECLINED";
    static REJECTED = "REJECTED";
    static COMPLETED = "COMPLETED";
    static CANCELLED = "CANCELLED";
}

class AddressTitle {
    static HOME = "HOME";
    static WORK = "WORK";
    static OTHER = "OTHER";
}

class MedeAddress {
    title?: AddressTitle;
    houseName?: string;
    houseNo?: string;
    flatNo?: number;
    streetName?: number;
    area?: string;
    city?: string;
    district?: string;
    state?: string;
    country?: string;
    pincode?: number;
    longitude?: number;
    latitude?: number;
}

class PersonalInfo {
    relationType: string;
}

export class MedePatient {
    patientId?: string;
    medexUID?: string;
    appUserDisplayId?: string;
    uhid?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    dobInEpoch?: number;
    gender?: Gender;
    email?: string;
    mobileNumber?: string;
    age?: string;
    dp?: string;

    heightInCm?: number;
    weightInKg?: number;

    address: MedeAddress;

    personalInfo?: PersonalInfo;
}

export class Gender {
    static MALE = "MALE";
    static FEMALE = "FEMALE";
    static OTHER = "OTHER";
}





export const MEDEX_ENCOUNTER_CASESHEET_ACCESS = {
    VIEWER: "VIEWER",
    EDITOR: "VIEWER",
    PROVIDER: "PROVIDER",
};

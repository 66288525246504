<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" id="top-nav-container">
    <div class="apps-icon">
        <button mat-icon-button color="#039be5" (click)="openAppSelector()">
            <mat-icon>apps</mat-icon>
        </button>
    </div>
    <div class="logo" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <img [src]="getLogoSrc()" id="logo-icon">
    </div>

    <div fxFlex>
        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of links" (click)="(activeLink = link) && navigateToApp(link)"
                [active]="activeLink == link"> {{link}} </a>
        </nav>
    </div>


    <div class="account-button">
        <button mat-icon-button fxFlexAlign="right" fxLayout="row" fxLayoutAlign="start center"
            [matMenuTriggerFor]="appMenu" *ngIf="userProfile">
            <img class="mat-elevation-z2" id="profile-img" [src]="getProfilePicture(userProfile.emailHash)">
        </button>
    </div>
    <mat-menu #appMenu="matMenu">
        <ng-template matMenuContent *ngIf="medeAuth.userProfile$|async as userProfile">
            <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px" id="app-menu">
                    <img [src]="getProfilePicture(userProfile.emailHash)" id="profile-img-big">
                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="6px">
                        <div class="mat-body-1">{{userProfile.email}}</div>
                        <div class="mat-caption" *ngFor="let role of userProfile['https://medeintegra.app/roles']">
                            {{role | titlecase}}</div>
                        <div class="mat-caption" *ngIf="facility">{{facility?.facilityName | titlecase}}</div>
                    </div>
                </div>
                

                <div fxLayout="column" fxLayoutAlign="center center" class="manage-account-button">
                    <button mat-raised-button color="warn" (click)="medeAuth.logout()" class="full-width-btn">
                        <mat-icon>power_settings_new</mat-icon>
                        Logout
                    </button>
                </div>

                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="mat-caption">Privacy Policy & Terms</div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="mat-caption">Version: {{appVersion}}</div>
                </div>
            </div>
        </ng-template>
    </mat-menu>
</div>

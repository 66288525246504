import { Pipe, PipeTransform } from '@angular/core';
import {VitaUser} from "../prohealth/vita-onboarding/vita-onboarding-service/onboarding.model";

@Pipe({
    name: 'vitaUserToName',
    pure: true
})
export class VitaUserToNamePipe implements PipeTransform {
    transform(vitaUser: VitaUser) {
        if (vitaUser.appUserInfo && vitaUser.appUserInfo.name) return vitaUser.appUserInfo.name;
        else return "";
    }
}


@Pipe({
    name: 'vitaUserToGender',
    pure: true
})
export class VitaUserToGender implements PipeTransform {
    transform(vitaUser: VitaUser) {
        if ( vitaUser && vitaUser.appUserInfo && vitaUser.appUserInfo.gender) return vitaUser.appUserInfo.gender.toLowerCase();
        else return "";
    }
}

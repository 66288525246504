import {Component, OnInit} from "@angular/core";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    constructor() {
    }

    // @HostListener("window:load",["$event"])
    // onLoad(e){
    //     console.log("loading",e);
    //     navigator.serviceWorker.register('../sw.js');
    //     console.log("service worker created")
    // }

    ngOnInit() {
        
        // if("serviceWorker" in navigator){
        //     navigator.serviceWorker.register('../sw.js');
        //     console.log("service worker created")
        // }
    }
}

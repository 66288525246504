import {Pipe, PipeTransform} from "@angular/core";
import {EncounterLifecycleEvent, EncounterServiceType} from "../medex-services/medex-encounters.model";

@Pipe({
    name: "EncounterServiceTypeDisplayName",
    pure: true
})
export class EncounterServiceTypeDisplayNamePipe implements PipeTransform {

    transform(serviceType: string) {
        switch (serviceType){
            case EncounterServiceType.AHC_DOCTOR_VC:
                return "Doctor Online";
            case EncounterServiceType.AHC_DOCTOR_AT_HOME:
                return "Doctor At Home";
            case EncounterServiceType.AHC_PHYSIO_AT_HOME:
                return "Physio At Home";
            case EncounterServiceType.AHC_NURSE_AT_HOME:
                return "Nurse at Home";
            case EncounterServiceType.FKHP_PRESCRIPTION_VALIDATION:
                return "Flipkart prescription Validation";
            default:
                return serviceType;
        }
    }
}

// TODO : SHould be moved to State Machine
@Pipe({
    name: "EncounterEventDisplayName",
    pure: true
})
export class EncounterEventDisplayNamePipe implements PipeTransform {

    transform(encounterEvent: string) {
        switch (encounterEvent){
            case EncounterLifecycleEvent.CREATE:
                return "Create";
            case EncounterLifecycleEvent.ACCEPT:
                return "Accept";
            case EncounterLifecycleEvent.CANCEL:
                return "Cancel";
            case EncounterLifecycleEvent.ASSIGN_CARE_PROVIDER:
                return "Assign Care Provider";
            case EncounterLifecycleEvent.COMPLETE:
                return "Complete";
            case EncounterLifecycleEvent.DECLINE:
                return "Decline";
            case EncounterLifecycleEvent.REJECT:
                return "Reject";
            default:
                return encounterEvent;
        }
    }
}

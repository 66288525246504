
export class MedeReadingType {
    static SYMPTOM = "symptom";

    static BLOOD_PRESSURE = "blood_pressure";
    static BODY = "body";
    static SPO2 = "spo2";
    static TEMPERATURE = "temperature";
    static ECG = "ecg";

    static BLOOD_GLUCOSE = "blood_glucose";
    static BLOOD_SUGAR = "blood_sugar";
    static HEMOGLOBIN = "hemoglobin";
    static CHOLESTEROL = "cholesterol";
    static URIC_ACID = "uric_acid";

    // Typhoid, Malaria, Dengue, Chikungunya, Hepatitis B, Hepatitis C, HIV, Syphilis
    static WHOLE_BLOOD_RDT = "whole_blood_rdt";

    static PREGNANCY = "pregnancy";
    static URINE_2P = "urine_2p";

    static RESTING_HR = "resting_hr";
    static HBA1C = "hba1c";
    static LIPID = "lipid";
    static THYROID = "thyroid";

    static DERMASCOPY = "dermascopy";
    static OTOSCOPY = "otoscopy";
    static MEDE_DOCUMENT = "mede_document";
    static RESPIRATORY_RATE = "respiratory_rate";
    static PULSE = "pulse";
}

export class OtoscopyTypes {
    // Otoscopy Types
    static LEFT_EAR = "left_ear";
    static RIGHT_EAR = "right_ear"
}

export class BloodPressure {
    systolic: number;
    diastolic: number;
    pulse: number;
    irrPulse?: boolean;
    respiratoryRate?: number;
}

export class Pulse {
    pulse: number;
}

export class BloodSugar {
    bloodSugar: number;
}

export class RespiratoryRate {
    respiratoryRate: number;
}

export class Body {
    height?: number;
    weight: number;
    bmi?: number;
    fat?: number;
}

export class Spo2 {
    spo2: number;
    pulse: number;
}

export class Temperature {
    temperature: number;
}

export class ECG {
    ecgResultUrl?: string;
}

export class Dermascopy {
    imageUrls?: string[];
    notes?: string;
}

export class OtoscopyImages {
    imageUrl: string;
    imageType: OtoscopyTypes
}
export class Otoscopy {
    images: OtoscopyImages[];
    notes?: string;
}




export class MedeReadingDocument {
    documentsUrl: string
    documentName: string
}

export class MedeReadingDocuments {
    documents?: MedeReadingDocument[]
    notes?: string
}



export class BloodGlucose {
    bloodGlucose: number;
    readingType?: string;        // Before Meal, After Meal, Random, Fasting
}

export class Hemoglobin {
    hemoglobin: number;         // Unit "g/dL"
}

export class Cholesterol {
    cholesterol: number;         // Unit "mg/dL"
}

export class UricAcid {
    uricAcid: number;           // Unit "mg/dL"
}


export class MedeReadingRdtType {
    static TYPHOID = "typhoid";
    static MALARIA = "malaria";
    static DENGUE = "dengue";
    static CHIKUNGUNYA = "chikungunya";
    static HEPATITIS_B = "hepatitis_b";
    static HEPATITIS_C = "hepatitis_c";
    static HIV = "hiv";
    static SYPHILIS = "syphilis";
    static D_DIMER = "d_dimer";
    static PROCALCITONIN = "procalcitonin";
    static CRP = "crp";
    static LEPTOSPIRA = "leptospira";
    static TROPONIN_1 = "troponin_1"
}
export class WholeBloodRDT {
    rdtTestType: MedeReadingRdtType;
    rdtResult: string;
    rdtTestImage?: string;
}

export class Pregnancy {
    rdtResult: string;
    rdtTestImage: string;

}

export class Urine2P {
    rdtResultGlucose: string;
    rdtResultProtein: string;
    rdtTestImage: string;
}

export class RestingHr {
    restingHr: number;
}

export class HbA1c {
    hbA1c; number;
}

export class Lipid {
    totalCholesterol: number;
    triglycerides: number;
    hdl: number;
    ldl: number;
}

export class Thyroid {
    t3: number;
    t4: number;
    tsh: number;
}




export class MedePatientSymptom {
    symptom: string;
    severity: string;
    onset?: number;
}


export class MedeReadingMetaInfo {
    createdBy: string;
    createdAt: number;
    updatedAt: number;
    updatedBy: string;
}

export class MedeReadingSourceInfo {
    sourceId?: string;
    sourceName: string;
    sourceType: string;         // Device, Manual etc.
    sourceDetails?: object;
}

export class MedeReadingReference {
    referenceId: string;
    referenceName: string;
}

export class MedeReading {
    medeUid: string;        // MedeUserId
    reference: MedeReadingReference;
    readingId: string;
    readingType: MedeReadingType;
    timestamp: number;
    metaInfo: MedeReadingMetaInfo;
    sourceInfo: MedeReadingSourceInfo;
    extraInfo: object;

    symptoms: MedePatientSymptom[];

    bloodPressure: BloodPressure;
    body: Body;
    spo2: Spo2;
    pulse: Pulse;
    bloodSugar: BloodSugar;
    temperature: Temperature;
    ecg: ECG;

    dermascopy: Dermascopy;
    otoscopy: Otoscopy;
    documents: MedeReadingDocuments;

    bloodGlucose: BloodGlucose;
    hemoglobin: Hemoglobin;
    cholesterol: Cholesterol;
    uricAcid: UricAcid;

    wholeBloodRDT: WholeBloodRDT;

    pregnancy: Pregnancy;
    urine2p: Urine2P;

    restingHr: RestingHr;
    hba1c: HbA1c;
    lipid: Lipid;
    thyroid: Thyroid;
    respiratoryRate: RespiratoryRate
}

export class MedeReports {
    title?: string;
    sampleCollectionDate?: string;
    reportedDate?: string;
    referredBy?: string;
    uhid?: string;
    patientName?: string;
    ageGender?: string;
    typeOfTest?: string;
    indicator?: Indicator[];
    location?: string;
    phoneNumber?: string;
    logo1?: string;
    logo2?: string;
}

export class Indicator {
    testName: string;
    result: string;
    unit: string;
    brf: string;
    method: string;
}


/*
    TODO : How to add/link symptoms to this set
    TODO : Define Units everywhere
 */
    export class ComponentNames {
        static MEDICATIONS = "MEDICATIONS";
        static LAB_INVESTIGATIONS = "LAB_INVESTIGATIONS";
        static SUMMARY = "SUMMARY";
        static READINGS = "READINGS";
        static READINGSV2 = "READINGSV2";
        static ALERTS = 'ALERTS';
        static MANAGE_DEVICE = 'MANAGE_DEVICE';
        static PRINT ='PRINT';
        static VITALS = 'VITALS';
        static NOTES = 'NOTES';
        static ADVICE = 'ADVICE';
        static TASKS = 'TASKS';
        static DOCUMENTS = 'DOCUMENTS';
        static VENTILATOR ='VENTILATOR';
        static ARTERIAL_BLOOD_GAS = 'ARTERIAL_BLOOD_GAS';
        static QUICK_ADD = 'QUICK_ADD';
        static HEMODYNAMICS = 'HEMODYNAMICS';
        static FLUIDS = 'FLUIDS';
        static PAST_HISTORY = 'PAST_HISTORY';
        static ACCOUNTS = 'ACCOUNTS';
        static PRESCRIPTION_VALIDATIONS = "PRESCRIPTION_VALIDATIONS";
        static CURRENT_PATIENT_SYMPTOMS = "CURRENT_PATIENT_SYMPTOMS";
        static CURRENT_SYMPTOMS = "CURRENT_SYMPTOMS";
        static LAB_TEST_RECORDS = "LAB_TEST_RECORDS";
        static PHYSICAL_EXAMINATION = "PHYSICAL_EXAMINATION";
        static SKIN_EXAMINATION = "SKIN_EXAMINATION";
        static ATTACHMENTS = "ATTACHMENTS";
        static DIAGNOSIS = "DIAGNOSIS";
        static INVESTIGATIONS = "INVESTIGATIONS";
        static REFERRAL = "REFERRAL";
        static REVIEW = "REVIEW";
        static PERSONAL_HISTORY = "PERSONAL_HISTORY";
        static FAMILY_HISTORY = "FAMILY_HISTORY";
        static DRUG_HISTORY = "DRUG_HISTORY";
        static SURGICAL_HISTORY = "SURGICAL_HISTORY";
        static ALLERGIES = "ALLERGIES";
        static DIET_ROUTINE = "DIET_ROUTINE";
        static PHYSICAL_ACTIVITY = "PHYSICAL_ACTIVITY";
        static WORK_LIFE = "WORK_LIFE";
        static PAST_HABITS = "PAST_HABITS";
        static DIAGNOSTIC_TESTS = "DIAGNOSTIC_TESTS";
        static MENSTURAL_HISTORY = "MENSTURAL_HISTORY";
        static EXTRA_ORAL_EXAMINATION = 'EXTRA_ORAL_EXAMINATION';
        static INTR_ORAL_EXAMINATION = 'INTR_ORAL_EXAMINATION';
        static DENTAL_TRATMENT_ADVISED = 'DENTAL_TRATMENT_ADVISED';
        static DENTAL_INVESTIGATIONS = 'DENTAL_INVESTIGATIONS';
        static DIABETES_COMPLICATIONS_ASSESSMENT = 'DIABETES_COMPLICATIONS_ASSESSMENT';
        static VPT = 'VPT';
        static SYSTEMIC_EXAMINATION = 'SYSTEMIC_EXAMINATION';
        static BREST_EXAMINATION = 'BREST_EXAMINATION';
        static CERVICAL_EXAMINATION = 'CERVICAL_EXAMINATION';
        static GENERAL_EXAMINATION = 'GENERAL_EXAMINATION';
        static SOCIAL_EXAMINATION = 'SOCIAL_EXAMINATION';
        static THYROID_EXAMINATION = 'THYROID_EXAMINATION';
        static OPHTHALMIC_EXAMINATION = 'OPHTHALMIC_EXAMINATION';
        static ENT_EXAMINATION = 'ENT_EXAMINATION';
        static LUNG_CANCER = 'LUNG_CANCER';
        static GASTROINTESTINAL_EXAMINATION = 'GASTROINTESTINAL_EXAMINATION';
        static MENSURAL_EXAMINATION = 'MENSURAL_EXAMINATION';
        static PROSTATE_EXAMINATION = 'PROSTATE_EXAMINATION';
        static GENITAL_EXAMINATION = 'GENITAL_EXAMINATION';
        static FKHP_PRESCRIPTION_VALIDATION = 'FKHP_PRESCRIPTION_VALIDATION'
        static NOTES_AND_ATTACHMENTS = 'NOTES_AND_ATTACHMENTS'
        static NCD_RISK_ASSESSMENT='NCD_RISK_ASSESSMENT';
        static AICVD_RISK_ASSESSMENT= 'AICVD_RISK_ASSESSMENT';
        static PREDIABETES_RISK_ASSESSMENT = 'PREDIABETES_RISK_ASSESSMENT';
        static PRO_HEALTH_FAMILY_HISTORY='PRO_HEALTH_FAMILY_HISTORY';
        static LAB_TEST_RESULT_CASESHEET_V2 = 'LAB_TEST_RESULT_CASESHEET_V2'
    }
    export class MedexFormControlType {
        static SHORT_TEXT = "short_text"
        static LONG_TEXT = "long_text"
        static DROPDOWN = "dropdown"
        static NUMBER = "number"
        static EMAIL = "email"
        static PHONE_NUMBER = "phone_number"
        static DATE = "date"
        static MULTISELECT = "multiselect"
        static BOOLEAN = "boolean"
        static FILE = "file"
        static CONDITIONAL_LOGIC = "conditional_logic"
      }    
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import {filter, map} from 'rxjs/internal/operators';
import {BehaviorSubject, Subject, Observable} from 'rxjs';
import {ACTION, ACTIVITYTYPE, HealthMentorEvent, TAB, VitaAppService} from './vita-app.service';
import { Store } from '@ngxs/store';
import {FirebaseApp} from "@angular/fire/compat";
import {environment} from "../../../environments/environment";
import {VitaCallState} from "../vita-routines/vita-call-service/vita-call-state";
import {DietMetric} from "../vita-diet/dietplan.model";

export class ActivitySession {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public activityType: string;
  public startTimestamp: number;
  public endTimestamp: number;
  public activeTime: number;
  public distance: number;
  public steps: number;
  public activeCalories: number;
  public timestamp : number;

}


export class Temperature {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public temperature: number;
  public symptoms: string[];
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class Hemoglobin {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public hemoglobin: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class UricAcid {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public uric_acid: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}


export class Creatinine {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public creatinine: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class Lipid {
  public vitaId: string;
  public dataID: string;
  public source: string;

  timestamp: number;
  total_cholesterol: number;
  triglycerides: number;
  hdl: number;
  ldl: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class Thyroid{
  public vitaId: string;
  public dataID: string;
  public source: string;

  timestamp: number;
  t3: number;
  t4: number;
  tsh: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}


export class HbA1c
{
  public vitaId: string;
  public dataID: string;
  public source: string;
  public date:number;
  public timestamp: number;
  public hbA1c: number;
  public metaInfo?: MetaInfo = new MetaInfo();
}

export class ActivitySummary {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public date: number;
  public activeTime: number;
  public distance: number;
  public steps: number;
  public caloriesExpended: number;
}

export class BloodGlucose {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public bloodGlucose: number;
  public mealSituation: string;
  public mealType: string;
  public symptoms: string[];
  public metaInfo?: MetaInfo = new MetaInfo();
}

export class BloodPressure {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public systolic: number;
  public diastolic: number;
  public pulse: number;
  public symptoms: string[];
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class Body {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public weight: number;
  public bmi: number;
  public fat: number;
  public metaInfo?: MetaInfo = new MetaInfo();

}

export class HeartRate {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public hr: number;
  public metaInfo?: MetaInfo = new MetaInfo();
}

export class RestingHeartRate {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public date: number;
  public restingHr: number;
  public metaInfo?: MetaInfo = new MetaInfo();
}

export class MedicationEvent {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public timezoneOffsetInMillis: number;
  public medicineId: string;
  public medicineName: string;
  public dosage: number;
  public unit: string;
  public form: string;
  public mealSituation: string;
  public mealType: string;
}

export  class VitaHabitEvent{
  vitaId: string;
  dataId: string;

  timestamp: number;

  taskId: string;
  taskTitle: string;
  done: boolean;

  compareString: string;
}


export class Spo2 {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public timestamp: number;
  public spo2: number;
  public pulse: number;
  public symptoms: string[];
  public metaInfo?: MetaInfo = new MetaInfo();

}

// export class Report {
//   public vitaId: string;
//   public dataID: string;
//   public timestamp: number;

//   public mimeType: string;
//   public reportName: string;
// }

export class Sleep {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public date: number;
  public sleepDuration: number;
}

export class Meals {
  public vitaId: string;
  public dataID: string;
  public source: string;

  public date: number;
  public calories: number;
  public carbohydrate: number;
  public fat: number;
  public protein: number;
  public fiber: number;
  public sodium: number;
  public mealType: string;
}

export class MealItem {
  public vitaId: string;
  public dataID: string;
  public source: string;
  public timestamp: number;
  public date: number;
  public calories: number;
  public carbohydrate: number;
  public fat: number;
  public protein: number;
  public fiber: number;
  public sodium: number;
  public mealType: string;
}

export class MealSummary {
  public vitaId: string;
  public dataID: string;
  public source: string;
  public date: number;
  public calories: number;
  public carbohydrate: number;
  public fat: number;
  public protein: number;
  public fiber: number;
  public sodium: number;
}

export class MetaInfo {
  updatedAt: number;
  deleted: boolean;
  source: string = "manual";
  createdAt: number;
  createdBy: string;
}


@Injectable({
  providedIn: "root"
})
export class VitaMetricsService {

  METRICS_URL: string;
  REPORTS_URL: string;
  DIET_TIMELINE_URL: string;

  activities = new BehaviorSubject<ActivitySession[]>([]);
  activities$ = this.activities.asObservable();

  activitySummary = new BehaviorSubject<ActivitySummary[]>([]);
  activitySummary$ = this.activitySummary.asObservable();

  bp = new BehaviorSubject<BloodPressure[]>([]);
  bp$ = this.bp.asObservable();

  bg = new BehaviorSubject<BloodGlucose[]>([]);
  bg$ = this.bg.asObservable();

  body = new BehaviorSubject<Body[]>([]);
  body$ = this.body.asObservable();

  restinghr = new BehaviorSubject<RestingHeartRate[]>([]);
  rhr$ = this.restinghr.asObservable();

  hr = new BehaviorSubject<HeartRate[]>([]);
  phr$ = this.hr.asObservable();

  mealItem = new BehaviorSubject<MealItem[]>([]);
  mealItem$ = this.mealItem.asObservable();

  sleep = new BehaviorSubject<Sleep[]>([]);
  sleep$ = this.sleep.asObservable();

  spo2 = new BehaviorSubject<Spo2[]>([]);
  spo2$ = this.spo2.asObservable();

  medicationEvents = new BehaviorSubject<MedicationEvent[]>([]);
  medicationEvents$ = this.medicationEvents.asObservable();

  habitEvents = new BehaviorSubject<VitaHabitEvent[]>([]);
  habitEvents$ = this.habitEvents.asObservable();

  temperature = new  BehaviorSubject<Temperature[]>([]);
  temperature$ = this.temperature.asObservable();

  hemoglobin = new  BehaviorSubject<Hemoglobin[]>([]);
  hemoglobin$ = this.hemoglobin.asObservable();

  uricacid = new  BehaviorSubject<UricAcid[]>([]);
  uricacid$ = this.uricacid.asObservable();

  creatinine = new  BehaviorSubject<Creatinine[]>([]);
  creatinine$ = this.creatinine.asObservable();

  hbA1c = new BehaviorSubject<HbA1c[]>([]);
  hbA1c$ = this.hbA1c.asObservable();

  lipid = new BehaviorSubject<Lipid[]>([]);
  lipid$ = this.lipid.asObservable();

  thyroid =  new BehaviorSubject<Thyroid[]>([]);
  thyroid$ = this.thyroid.asObservable();

  diet = new BehaviorSubject<DietMetric[]>([]);
  diet$ = this.diet.asObservable();

  onMetricChanged = new Subject()


  // report = new BehaviorSubject<Report[]>([]);
  // report$ = this.report.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(FirebaseApp) firebaseApp: any,
    private vitaAppService: VitaAppService,
    private store: Store
  ){
    this.METRICS_URL = environment.vitacloud.data.url;
    this.REPORTS_URL = environment.vitacloud.blob.url;
    this.DIET_TIMELINE_URL = environment.vitacloud.data.url + environment.vitacloud.data.path.mealTimeine;

  }



  getVitaUserData(metricType){
    let end = moment().endOf('day').unix();
    // let start: any = moment().subtract(1, 'year');
    // start = start.unix();
    let start = this.store.selectSnapshot(VitaCallState.getUserEnrollmentDate);
    start  = start
    console.log("metrics start date", start);
    let url = this.METRICS_URL + "/" + metricType + '?from=' + start + '&to=' + end;
    console.log("Checking data", metricType);
    switch (metricType) {
      case MetricsType.METRIC_ACTIVITYSUMMARY:
        url = this.METRICS_URL + "/activity/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_ACTIVITY:
        url = this.METRICS_URL + "/activity/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_BODY:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_BG:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_BP:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_MEDICATION_EVENT:
        url = this.METRICS_URL + "/events/medication" + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_HABIT_EVENT:
        url = this.METRICS_URL + "/events/behaviour" + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_TEMPERATURE:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_Hemoglobin:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_URICACID:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_CREATININE:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_HBA1C:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_LIPID:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_THYROID:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;
      case MetricsType.METRIC_SPO2:
        url = this.METRICS_URL + "/vitals/" + metricType + '?from=' + start + '&to=' + end;
        break;

      case MetricsType.METRIC_DIET:
        url = this.DIET_TIMELINE_URL;
        break;
      // case MetricsType.REPORT:
      //   url = this.REPORTS_URL + "/vitareports/list";
      //   break;
    }

    let headers      = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization' : "Open " + this.vitaAppService.getOpenToken()
    });
    let options = {headers: headers};

    console.log("Sending get request to URL ", url, options);
    return this.http.get(url, options)
      // .pipe(map((body: any) => body.data.metrics))
      .pipe(map((body: any) => body.data))
      .pipe(filter(metrics => metrics && metrics.length && metrics.length > 0))
      .pipe(map((metrics: any[]) => {
        metrics.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            if (a.timestamp > b.timestamp) return 1;
            else return -1;
          }
          if (a.date && b.date) {
            if (a.date > b.date) return 1;
            else return -1;
          }
          return 0;
        });

        // console.log('metrics data to filter', metrics);
        if(metricType === MetricsType.METRIC_DIET) {

        }

        else {
          metrics = metrics.filter(data => data && data.metaInfo && data.metaInfo.deleted === false);
        }

        switch (metricType){
          case MetricsType.METRIC_ACTIVITYSUMMARY:
            console.log("Calling onNext on activitySummary ", metrics);
             this.activitySummary.next(metrics);
            break;
          case MetricsType.METRIC_ACTIVITY:
            this.activities.next(metrics);
            break;
          case MetricsType.METRIC_BODY:
            this.body.next(metrics);
            break;
          case MetricsType.METRIC_BG:
            this.bg.next(metrics);
            break;
          case MetricsType.METRIC_BP:
            this.bp.next(metrics);
            break;
          case MetricsType.MEAL_ITEM:
            this.mealItem.next(metrics);
            break;
          case MetricsType.HEART_RATE:
            this.hr.next(metrics);
            break;
          case MetricsType.RESTING_HEART_RATE:
            this.restinghr.next(metrics);
            break;
          case MetricsType.METRIC_SLEEP:
            this.sleep.next(metrics);
            break;
          case MetricsType.METRIC_SPO2:
            console.log(metricType, metrics);
            this.spo2.next(metrics);
            break;
          case MetricsType.METRIC_MEDICATION_EVENT:
            console.log(metricType, metrics);
            this.medicationEvents.next(metrics);
            break;
          case MetricsType.METRIC_HABIT_EVENT:
            console.log(metricType, metrics);
            this.habitEvents.next(metrics);
            break;

          case MetricsType.METRIC_TEMPERATURE:
            console.log(metricType, metrics);
            this.temperature.next(metrics);
            break;

          case MetricsType.METRIC_Hemoglobin:
            console.log(metricType, metrics);
            this.hemoglobin.next(metrics);
            break;
          
          case MetricsType.METRIC_URICACID:
            console.log(metricType, metrics);
            this.uricacid.next(metrics);
            break;

          case MetricsType.METRIC_CREATININE:
            console.log(metricType, metrics);
            this.creatinine.next(metrics);
            break;

          case MetricsType.METRIC_HBA1C:
            console.log(metricType, metrics);
            this.hbA1c.next(metrics);
            break;

          case MetricsType.METRIC_LIPID:
            console.log(metricType, metrics);
            this.lipid.next(metrics);
            break;

          case MetricsType.METRIC_THYROID:
            console.log(metricType, metrics);
            this.thyroid.next(metrics);
            break;

          case MetricsType.METRIC_DIET:
            console.log("diet timeline", metricType, metrics);
            this.diet.next(metrics);

          // case MetricsType.REPORT:
          //   console.log("Reports metric", metricType, metrics);
          //   this.report.next(metrics);
          //   break;
        }

        console.log("Calling onNext on body", metrics);
        // const startDate = moment().subtract(3, 'months').startOf('day').unix();
        // const threeMonthsData = metrics.filter(data => data.timestamp > startDate);
        // console.log('3months data body', threeMonthsData);
        // if (threeMonthsData && threeMonthsData.length > 0) {
        //   return threeMonthsData;
        // }
        // else {
        //   return [metrics.slice(-1).pop()];
        // }

        return metrics;
      }));
  }

  saveVitaUserData(metricType: MetricsType, data: any): Observable<any>{

    let bodyString = JSON.stringify({
      data : data
    }); // Stringify payload
    this.vitaAppService.saveMetricEvent();

    let headers      = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization' : "Open " + this.vitaAppService.getOpenToken()
    });
    let options = {headers: headers};

    console.log("if error", headers);
    console.log(options, bodyString);
    console.log(this.METRICS_URL + '/vitals/' + metricType + '/add');
    return this.http.post<any>(this.METRICS_URL + '/vitals/' + metricType + '/add', bodyString, options);
  }

  

}


export class MetricsType{
  static METRIC_ACTIVITY = "activity";
  static METRIC_ACTIVITYSUMMARY = "activitysummary";
  static METRIC_BODY = "body";
  static METRIC_BP = "bloodpressure";
  static METRIC_BG = "bloodglucose";
  static MEAL_ITEM = "mealitem";
  static HEART_RATE = "heartrate";
  static RESTING_HEART_RATE = "restinghr";
  static METRIC_SLEEP = "sleepsummary";
  static METRIC_SPO2 = "spo2";
  static METRIC_MEDICATION_EVENT = "medication";
  static METRIC_HABIT_EVENT = "habitevents";
  static METRIC_STRESS = "stress";
  static METRIC_TEMPERATURE = "temperature";
  static METRIC_Hemoglobin = "hemoglobin";
  static METRIC_URICACID = "uricacid";
  static METRIC_CREATININE = "creatinine";
  static METRIC_HBA1C = "hba1c";
  static METRIC_LIPID = "lipid";
  static METRIC_THYROID = "thyroid";
  static METRIC_REPORT = "report";
  static METRIC_DIET = "diet";
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'momentDate'
})
export class DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log("pipe date:", value+', moment:'+moment(value).format("DD-MM-YYYY"));
    return moment(value).format("DD-MM-YYYY");
  }

}

import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    UrlTree, CanLoad, Route, UrlSegment
} from "@angular/router";
import { Observable } from "rxjs";
import { MedeAuthService } from "./mede-auth.service";
import { filter, map, tap } from "rxjs/operators";
import {APP_SCOPES} from "../scopes";

@Injectable({
    providedIn: "root"
})
export class MedexScopeGuard implements CanActivate {

    constructor(
        private medeAuth: MedeAuthService,
        private router: Router
    ) { }

    shouldAllow(path){

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log("Role Guard activated ");
        return this.medeAuth.userProfile$.pipe(
            tap(userProfile => console.log("User Profile in Role Gaurd ", userProfile)),
            filter(userProfile => userProfile !== null),
            map(userProfile => {

                console.log("Route URl ", route.url);
                const scopes = userProfile.scopes || [];


                if (route.url.length > 0){
                    return true;
                }
                else {
                    console.log("No Scopes found. Logging out ");
                    this.medeAuth.logout();
                    return true;
                }
            }),
        );
    }
}

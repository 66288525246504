import * as moment from 'moment';
import {VitaRoutineTouchPointCategory} from "../../vita-services/routines/vita-routines";


// TODO : Placeholder for generic reports

// TODO: Compulsory Investigations
// TODO : Some Readings needs to be outside the touchpoints - STDs atleast once done during entire program - but can be done during anytime of the program

export const CallLogMetric = {
  BLOOD_PRESSURE : "bloodPressure",
  BODY: "body", // Height, Weight, BMI, Hip, Waist, Ratio
  HEMOGLOBIN: "hemoglobin",

  CBP: "cbp", // Complete Blood Picture - Reports are Emailed - Form to be obtained
  CUE: "cue", // Complete Urine Examination - Reports are Emailed - Form to be obtained
  HIV: "hiv", //
  VDRL: "vdrl", //
  HBSAG: "hbsag", //

  PRE_PREGNANCY_WEIGHT: "prePregnancyWeight",

  COMPLAINTS: "complaints",
  MEDICAL_CONDITION: "medicalCondition",
  LAST_ANTENTAL_CHECK: "lastAntentalCheck", // Ante-Natal -
  SLEEP_PATTERN: "sleepPattern",            //
  STATUS_OF_PHYSICAL_ACTIVITY: "statusOfPhysicalActivity",
  ULTRASOUND_SCAN: "ultrasoundScan", // Add Date

//   TT_IMMUNIZATION: "ttImmunization",

  MEDICATION: "medications", // Database can be used - But with common search list would be great
    // Medications Prescribed should be seperate from Knowing existing medications taken


  DIET: "diet",
  DELIVERY_DETAILS: "deliveryDetails",

  BABY: "baby",

  POST_NATAL: "postNatal",

  MISCARRIAGE: "miscarriage",

  EARLY_ULTRASOUND_SCAN: "earlyUltrasoundScan",
  SCREENING_FOR_GESTATIONAL_DIABETES: "screeningForGestationalDiabetes",
  SIGNS_OF_PIH : "signsOfPIH",
  SIGNS_OF_GDM : "signsOfGDM",
  ANTENTAL_CHECK_DONE_TILL_DATE : "antenatalCheckupsDoneTillDate",
  IMMUNIZATION_COUNSELLING_DONE  : "immunizationCounsellingDone",
  BREASTFEEDING_COUNSELLING_DONE  : "breastFeedingCounsellingDone",

  // post natal call
  VACCINATION_CHART: "vaccinationChart",
  VACCINATION_GIVEN: "vaccinationGiven",
  BABY_AGE: "babyAge",
  BABY_WEIGHT: "babyWeight",
  EXCEPCTED_BABY_WEIGHT: "expectedBabyWeight",
  COMPLICATIONS_INFANT: "complicationsInfant",
  COMPLAINT_OF_INFANT: "complaintOfInfant",
  //   ADVICE_FOR_INFANT: "adviceForInfant",
  EPISOTOMY_WOUND_LSCS_WOUND: "episotomyWoundLscsWound",
  LOCHIA_VAGINAL_DISCHARGE: "lochiaVaginalDischarge",
  BREAST_NIPPLE_CHANGES: "breastNippleChanges",
  WEIGHT_OF_MOTHER: "weightOfMother",
  COMPLICATIONS_MOTHER: "complicationsMother",
  COMPLAINT_OF_MOM: "complaintOfMom",
  //   ADVISE_FOR_MOM: "adviceForMom",
//   DO_YOU_HAVE_HELP_AT_HOME_TO_TAKE_CARE_OF_THE_CHILD: "doYouHaveHelpAtHome",
//   PHYSICAL_ACTIVITY_FOR_MOM_POST_DELIVERY: "physicalActivityForMomPostDelivery",
//   DISASTER_IF_ANY: "disasterIfAny",
  QUESTIONNAIRE: "questions",


  // Infant Diet Call
  BIRTH_WEIGHT: "Birth Weight",
  RECUMBENT_LENGTH: "Recumbent Length",
  CURRENT_FEEDING_PATTERN: "Current Feeding Pattern",
  LAST_DOCTOR_VISITING_DATE: "Last Doctor visiting date",
  DIET_PLAN_SENT_DATE_AND_TIME: "Diet plan sent Date & Time",
  LINK: "Link",

  // Psychology Call
  CALL_TO: "Call To",

  // New Addons in DietCall
  ALLERGIES: "Allergies",

};

export class Vitals {
    bloodGlucose?: BloodGlucose;
    bloodPressure?: BloodPressure;
    body?: Body;
    hemoglobin?: Hemoglobin;
}

export class Hemoglobin {
    hemoglobinPercentage: number;
    hemoglobinStatus: string;
}
export class BloodGlucose {
    timestamp: number;
    mealSituation = "MEAL_SITUATION_RANDOM";
    mealType?: string;
    bloodGlucose: number;
    symptoms: string[];
    extraInfo?: string;
    metaInfo: MetaInfo;
}

export class BloodPressure {
    timestamp?: number;
    systolic: number;
    diastolic: number;
    pulse?: number;
    irrPulse ?= false;
    symptoms?: string[];
    extraInfo?: string;
    metaInfo?: MetaInfo;
    bpStatus?: string;
}

export class CBP{
    verifyCBP: boolean;
    cbp: any;
}

export class EarlyUltrasoundScan {
    scanDate: number;
    scanNote: string;
}

export class ScreeningForGestationalDiabetes {
    screeningDate: number;
    screeningNote: string;
}

export class CUE{
    verifyCUE: boolean;
    cue: any;
}

export class HIV{
    verifyHIV: boolean;
    hiv: any;
}

export class VDRL{
    verifyVDRL: boolean;
    vdrl: any;
}

export class HBASG{
    verifyHbsAg: boolean;
    HbsAg: any;
}

export class PrePregnancyWeight {
    weightBeforePregnancy: number;
}

export class TTImmunization1 {
    verifyTT1: any;
    tt1Date: any;
}

export class TTImmunization2 {
    verifyTT2: any;
    tt2Date: any;
}

export class ExtraInfo {
    complaints?: string;
    dietaryAdvice?: string;
    generalAdvice?: string;
    complaintsFreeText?: string;
}

export class Body{
    timestamp: number;
    weight?: number;
    fat: number;
    bmi: any;
    extraInfo?: string;
    metaInfo: MetaInfo;
    feets?: number;
    inches?: number;
    centimeters?: number;
    height?: number;
    heightVerify?: boolean;
}

export class MedicalConditions {
    medicalCondition: string;
    medicalNote: string;
}


export class Sleep {
    sleepDuration: number;
    sleepPattern: string;
    timestamp: number;
}

export class Medication {
    mediceneName: string;
    dosage: number;
    unit: string;
    mealSituation: string;
    mealType: string;
    timestamp: number;
    note: string;
}

export class MedicationCallLog {
    medication: string;
}

export class MedicalTest {
    testName: string;
    testType: string;
    timestamp: number;
    comments: string;
    result: any;
    unit: string;
}

export class UltrasoundScan {
    verifyUltra: any;
    comments: string;
    reportId: string;
}

export class Advice {
    notes: string;
    category: string;
    type: string;
    routineId: string;
    createdBy: string;
}

export class Diet {
    dietaryPattern: string;
    foodCravings: string;
    appetite: string;
    dietType: string;
    allergies: string;
}

export class Pregnancy {
    lastAntentalCheck?: any;
    typeOfDelivery?: string;
    statusOfDelivery?: string;
    complications?: string;
    timestamp?: number;
    prePregnancyWeight?: PrePregnancyWeight;
    gestinationalWeek?: number;
    motherHealthStatus?: string;
    dateOfMiscarriage?: number;
    reasonOfMiscarriage?: string;
    ttImmunization1?: TTImmunization1;
    ttImmunization2?: TTImmunization2;
    dateOfDelivery?: number;
    complaintsDuringDelivery?: string;
    misscarriageReason?: any;

    // tslint:disable-next-line: no-use-before-declare
    signsOfPIH?: SignsOfPIH = new SignsOfPIH();

    // tslint:disable-next-line: no-use-before-declare
    signsOfGDM?: SignsOfGDM = new SignsOfGDM();
    antenatalCheckupsTillDate?: number;
    immunizationCounsellingDone?: number;
    breastFeedingCounsellingDone?: number;

}


export class SignsOfPIH {
    signsOfPIH?: string;
    pihNote?: string;
}

export class SignsOfGDM {
    signsOfGDM?: string;
    gdmNote?: string;
}

export class LastAntentalCheck {
    checkUpDate: any;
}

export class Baby {
    weight?: number;
    condition?: string;
    gender?: string;
    lochia?: string;
    breastFeeding?: string;
    bloodGroup?: string;
    vaccinationStatus?: string;
    babyHealthStatus?: string;
    motherHealthStatus?: string;
    woundCondition?: string;
}

export class MedicalCondition {
    conditionName?: string;
    conditionType?: string;
    timestamp?: number;
}

export class CallDetails {
    callDuration: string;
    dateOfCall: any;
}

export class Activity {
    sleep: Sleep;
    statusOfPhysicalActivity: string;
}

export class SleepActivity {
    sleepPattern: string;
}



export class CallLog{
  calllog: CallLogData;
  callLogCategory?: VitaRoutineTouchPointCategory;
  calllogId?: string;
  timestamp: number;
  touchPointId?: any;
  metaInfo: MetaInfo = new MetaInfo();
  vitals?: Vitals;
  medication?: Medications;
  activity?: SleepActivity;
  extrainfo: ExtraInfo;
  diet?: Diet
  type?: string;
  postNatalCall?: PostNatalCall;
}

export class MetaInfo {
  // To Be Set by client
  updatedAt: number = moment().unix();
  deleted = false;
  deletedAt: number;

  // Set By Client and Authorized by Server
  source = "manual";

  // To Be Set by Server
  createdAt: number = moment().unix();
  updatedBy: string;
  createdBy: string;
}

export class CallLogData {
    vitals?: Vitals;
    activity?: Activity;
    medications?: Medication[];
    medicalConditions?: MedicalCondition[];
    diet?: Diet;
    pregnancy?: Pregnancy;
    medicalTests?: MedicalTest[];
    baby?: Baby;
}


export class Medications {
    mediCond?: string;
    medicalNote?: string;
}

export class BabyAge {
    months: any;
    weeks: any;
    days: any;
}

export class Questionnaire {
    helpAtHome: string;
    physicalActivity: string;
    disaster: string;
    reason: string;
}

export class PostNatalCall {
    vaccinationChart: string;
    vaccinationGiven: string;
    vaccinationDate: number;
    babyAge: BabyAge = new BabyAge();
    babyWeight: number;
    babyExpectedWeight: number;
    infantComplications: string;
    infantComplaints: string;
    episotomyWoundLSCSwound: string;
    lochiaVaginalDischarge: string;
    breastNippleChanges: string;
    motherComplications: string;
    questionnaire: Questionnaire = new Questionnaire();
}


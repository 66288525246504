export const APP_SCOPES = {
    NOTIFICATIONS: "notifications",
    SETTINGS: "settings",

    AUDITOR: "auditor",
    INSIGHTS: "insights",

    FACILITY_APPOINTMENTS: "facility_appointments",
    MY_APPOINTMENTS: "my_appointments",
    MY_PATIENTS: "patients",
    ALL_APPOINTMENTS: "all_appointments",

    PATIENT_REGISTRATION: "patient_registration",

    MY_ADMISSIONS: "my_admissions",
    MY_ADMISSION_HISTORY: "my_admissions_history",

    ALERTS: "alerts",
    IOMT_ALERTS: "iomt_alerts",


    ALL_ENCOUNTERS: "all_encounters",
    MY_ENCOUNTERS: "my_encounters",
    FACILITY_ENCOUNTERS: "facility_encounters",

    AHC_PROVIDERS_AVIALABILITY: "care_provider_availability",
    AHC_PROVIDER_TEAMS: "care_providers_teams",
    AHC_BOOKINGS: "ahc_bookings",
    AHC_PATIENT_SEARCH: "ahc_patient_search",


    PROHEALTH: "prohealth",
    PROHEALTH_REGISTRATION: "prohealth:registration",
    PROHEALTH_SEARCH: "prohealth:search",
    PROHEALTH_HM_CALLINFO : "prohealth:hmcallInfo",
    PROHEALTH_HM_CALL_STATUS_HISTORY : "prohealth:call-status-history",
    PROHEALTH_CHARTS: "prohealth:charts",

    PHC_PATIENT_REGISTRATION: "phc_patient_registration",

    BOOK_STAYI: "book:stayi",

    BOOK_ADMISSION: "book:admission",

    BOOK_TELANGANA_ICU_ADMISSION: "book:telanganaicu",

    BOOK_ECC_ADMISSION: "book:ecc",

    BOOK_ER_ADMISSION: "book:er",

    all_patients: "patients",
    review_appointments: "review_appointments",


    all_admissions: "all_admissions",

    bookings: "booking_requests",
    transactions: "transactions",

    reading_alerts: "reading_alerts",

    availability: "availability",
    presence: "live_doctor_status",
    doctors: "doctors",
    projects: "projects",
    referrals: "referrals",
    TASKS: "tasks",

    resources: "resources",


    MANAGE_RESOURCES: "manage_resources"
};

/*
        Sample Roles

        Auditor
            -

        Coordinator
            -

        OP Doctor
            -

        IP Doctor
            -
*/

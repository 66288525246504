import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MedeAuthGuard } from "./mede-auth/mede-auth.guard";
import { DashboardComponent } from "./app-commons/dashboard/dashboard.component";
import { InterceptorService } from "./mede-auth/mede-auth.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MedexDefaultScopeRedirect } from "./mede-auth/medex-default-scope-redirect.service";
import { MedexScopeGuard } from "./mede-auth/medex-scope-guard.service";
import { APP_SCOPES } from "./scopes";

const routes: Routes = [
    {
        path: "auth",
        loadChildren: () => import("./mede-auth/mede-auth.module").then(m => m.MedeAuthModule)
    },
    {
        path: "",
        component: DashboardComponent,
        canActivate: [MedeAuthGuard],
        children: [
            
            {
                path: APP_SCOPES.PROHEALTH,
                canActivate: [MedexScopeGuard],
                loadChildren: () => import("./prohealth/prohealth.module").then(m => m.ProhealthModule)
            },
            {
                path: "",
                canActivate: [MedexDefaultScopeRedirect],
                loadChildren: () => import("./app-commons/app-commons.module").then(m => m.AppCommonsModule)
            }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: "legacy",
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        onSameUrlNavigation: "reload"
    })],
    exports: [RouterModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }
    ]
})
export class AppRoutingModule {
}


import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { APP_SCOPES } from "../../scopes";

/**
 * Purpose - To show side nav bar options
 */
@Component({
    selector: "app-side-nav",
    templateUrl: "./side-nav.component.html",
    styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
    @Input() scopes = [];
    @Input() role = "";
    selectedIndex = 0

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    toggle = true;
    toggleTrack: Subscription;
    trackStatus: Subscription;
    public isCollapsed = true;


    ngOnInit() {
    }

    getTooltipForScope(scope: string) {
        // console.log("Scope", scope);

        switch (scope) {
            case "prohealth:registration":
                return "Registration";
            case "prohealth:search":
                return "Search";
            case "prohealth:charts":
                return "Charts";
            case "prohealth":
                return "Dashboard";
            case APP_SCOPES.PROHEALTH_HM_CALL_STATUS_HISTORY:
                return "Call Status History";
            case APP_SCOPES.PROHEALTH_HM_CALLINFO:
                return "Call Info";    
            default:
                return "";
        }
    }

    getMatIconNameForScope(scope: string) {
        switch (scope) {
            case "prohealth:registration":
                return "assignment_turned_in";
            case "prohealth:search":
                return "search";
            case "prohealth:charts":
                return "assessment";
            case APP_SCOPES.PROHEALTH_HM_CALLINFO:
                return "add_ic_call";
            case APP_SCOPES.PROHEALTH_HM_CALL_STATUS_HISTORY:
                return "history";
            default:
                return "dashboard";   
        }
    }

    setSelectedMenu(scope: string, role: string, index: number) {
        const selectedMenu = this.getrouterLinkForScope(scope, role, index);
        this.router.navigate([selectedMenu]);
        this.selectedIndex = index
    }

    getrouterLinkForScope(scope: string, role: string, index: number) {
        console.log("index", index)
        this.selectedIndex = index
        switch (scope) {
            case APP_SCOPES.PROHEALTH_REGISTRATION:
                return "/prohealth/onboarding/users";
            case APP_SCOPES.PROHEALTH_SEARCH:
                return "/prohealth/searchByUhid";
            case APP_SCOPES.PROHEALTH_HM_CALLINFO:
                return "/prohealth/hmCallInfo";
            case APP_SCOPES.PROHEALTH_CHARTS:
                return "/prohealth/charts";
            case APP_SCOPES.PHC_PATIENT_REGISTRATION:
                return "/prohealth/health-check/patient/register";
            case APP_SCOPES.PROHEALTH_HM_CALL_STATUS_HISTORY:
                    return "/prohealth/call-status-history";    
            default:
                return `/${scope}`;
        }
    }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { MedeReading, MedeReadingType, MedeReports } from "./medex-readings.model";
import { filter, map, share, take, tap } from "rxjs/operators";
import * as moment from "moment";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class MedeReadingsService {
    private onReadingsUpdate = new Subject();
    onReadingsUpdate$ = this.onReadingsUpdate.asObservable();
    private onReadingsUpdateIomt = new Subject();
    onReadingsUpdateIomt$ = this.onReadingsUpdateIomt.asObservable();
    private iomtReadingsDataToPrint = new Subject<any>();
    iomtReadingsDataToPrint$ = this.iomtReadingsDataToPrint.asObservable();
    private timeIntervalToPrint = new Subject<any>();
    timeIntervalToPrint$ = this.timeIntervalToPrint.asObservable();
    reports: MedeReports = {};
    constructor(
        private httpClient: HttpClient,
        
    ) {
    }

    // getPreferenceByPreferenceTypeAndSourceId(preferenceType, sourceId) {
    //     return this.httpClient.get<any>(environment.medexIam.url + environment.medexIam.preferences.getPreferenceByPreferenceTypeAndSourceId + "/" + preferenceType + "/sourceId/" + sourceId).pipe(map((res) => res));
    // }
    createEncounter(payload) {
        const options = {
            headers: new HttpHeaders({}),
        };
        return this.httpClient.post(environment.encounters.url + environment.encounters.create, payload, options);

    }
}

<mat-drawer-container class="example-container" [hasBackdrop]="true">
    <mat-drawer #drawer [mode]="'over'">
        <app-medex-apps-nav></app-medex-apps-nav>
    </mat-drawer>
    <mat-drawer-content>
        <div fxLayout="column" fxFlex class="main-container">
            <div id="top-nav" *ngIf="!isFullScreenMode" class="border-r hidden lg:block">
                <app-top-nav (toggleApps)="drawer.toggle()"></app-top-nav>
            </div>

            <div fxLayout="row">

                <app-side-nav class="border-r hidden lg:block" [scopes]="roleBasedScopes" [role]="role"
                    *ngIf="!isFullScreenMode">
                </app-side-nav>
                <div [ngClass]="!isFullScreenMode ? 'dashboard' : 'dashboard-dark'" fxFlex>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-bottom-nav class="border-r lg:hidden"></app-bottom-nav>
    </mat-drawer-content>
</mat-drawer-container>
import { HttpClient } from '@angular/common/http';
import {Injectable, ModuleWithProviders, NgModule} from '@angular/core';
import { environment } from 'src/environments/environment';


@NgModule({})
export class VitaAppSharedModule {
  static forRoot(): ModuleWithProviders<VitaAppSharedModule> {
    return {
      ngModule: VitaAppSharedModule,
      providers: [VitaAppService]
    };
  }
}

export class HealthMentorEvent{
  userId: string;
  timestamp: number;
  activityType: ACTIVITYTYPE;
  tabName: TAB;
  action: ACTION;
  entityId: string; 
}

export enum ACTIVITYTYPE {
  LOGIN ="LOGIN",
  VITAL = "VITAL",
  TOUCHPOINT = "TOUCHPOINT",
  OTHERS = "OTHERS",
  COMMUNICATION ="COMMUNICATION",
  WHATSAPP = "WHATSAPP",
  SMS ="SMS",
  REPORTS = "REPORTS",
  NOTES = "NOTES"
}

export enum TAB{
  CLINICALSUMMARY = "CLINICALSUMMARY",
  FOLLOWUP = "FOLLOWUP",
  READINGS = "READINGS",
  CONTACT_PREFERENCES = "CONTACT_PREFERENCES",
  NOTES ="NOTES",
  CAREPLAN = "CAREPLAN",
  COMMUNICATION = "COMMUNICATION"
}

export enum ACTION {
  EDIT = "EDIT",
  CREATE = "CREATE",
  CLICK = "CLICK"
}

@Injectable({
  providedIn: 'root',
})
export class VitaAppService {

  SEND_HEALTHMENTOR_EVENT:string;

  openToken: string;
  userId: string;
  providerId: string;
  scopes: string[];
  idToken: String;

  providerToken: string;

  constructor(    private http: HttpClient,
    ) { 
    this.SEND_HEALTHMENTOR_EVENT = environment.vitacloud.auth.url + environment.vitacloud.auth.path.saveEvent
  }

  setProviderId(providerId: string){
    this.providerId = providerId
  }

  getProviderId(): string{
    return this.providerId;
  }

  setProviderToken(providerToken: string){
    this.providerToken = providerToken;
  }

  getProviderToken(): string{
    return this.providerToken;
  }

  setOpenToken(opentoken){
    this.openToken = opentoken;
  }

  getOpenToken(){
    return this.openToken;
  }

  setUserId(userId: string){
    this.userId = userId;
  }

  getUserId(): string{
    return this.userId
  }

  setScopes(scopes: string[]){
    this.scopes = scopes;
  }

  getScopes(): string[]{
    return this.scopes;
  }


  setIdToken(idToken: string){
    this.idToken = idToken;
  }

  getIdToken(): String{
    return this.idToken;
  }

  getProviderEmail() {
    return localStorage.getItem("providerEmailKey");
  }

  getProviderName(){
    return localStorage.getItem("providerNameKey");
  }

  logEvent(event:any) {
    return this.http.post<any>(`${this.SEND_HEALTHMENTOR_EVENT}`,event, {})  
  }

  saveCommunicationEvent(evntType:ACTIVITYTYPE){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.activityType = evntType;
    event.action = ACTION.CREATE;
    event.timestamp = Math.floor(new Date().getTime()/1000);
    event.tabName = TAB.COMMUNICATION;
    event.entityId = this.getUserId();
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }

  saveMetricEvent(){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime() / 1000);
    event.activityType = ACTIVITYTYPE.VITAL;
    event.tabName = TAB.READINGS;
    event.action = ACTION.CREATE;
    event.entityId = this.getUserId();
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }

  saveNoteEvent(){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime() / 1000);
    event.activityType = ACTIVITYTYPE.NOTES;
    event.tabName = TAB.NOTES;
    event.action = ACTION.CREATE;
    event.entityId = this.getUserId();
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }


  saveEventByTab(tab:string){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime()/1000);
    event.action = ACTION.CLICK;
    event.activityType = ACTIVITYTYPE.OTHERS;
    event.entityId = this.getUserId();
    switch (tab) {
      case "Readings":
        event.tabName = TAB.READINGS;
        break;
      case "Notes":
        event.tabName = TAB.NOTES;
        break;
      case "Care Plan":
        event.tabName = TAB.CAREPLAN;
        break;
      case "Clinical Summary":
        event.tabName = TAB.CLINICALSUMMARY;
        break;
      case "Contact Preferences":
        event.tabName = TAB.CONTACT_PREFERENCES;
        break;
      case "Follow Up":
        event.tabName = TAB.FOLLOWUP;
        break;
      case "Communication":
        event.tabName = TAB.COMMUNICATION;
        break;
    }
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }

  saveEventByTouchPointAction(action: ACTION){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime() / 1000);
    event.activityType = ACTIVITYTYPE.TOUCHPOINT;
    event.tabName = TAB.FOLLOWUP;
    event.action = action;
    event.entityId = this.getUserId();
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }

  saveLoginEvent(){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime() / 1000);
    event.activityType = ACTIVITYTYPE.LOGIN;
    event.action = ACTION.CLICK;
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }

  saveEventByReportAction(action: ACTION){
    const event = new HealthMentorEvent();
    event.userId = this.getProviderEmail();
    event.timestamp = Math.floor(new Date().getTime() / 1000);
    event.activityType = ACTIVITYTYPE.REPORTS;
    event.tabName = TAB.NOTES;
    event.action = action;
    event.entityId = this.getUserId();
    this.logEvent(event).subscribe({
      next: (response) => {
        console.log('Event logged successfully:', response);
      },
      error: (error) => {
        console.error('Error logging event:', error);
      }
    });
  }
}



export class ProgramName {
    public static readonly type = "[program name] ProgramName";
    constructor(public payload: string) {
        console.log('payload', payload);
    }
}

export class UserVitaId {
    public static readonly type = '[User VitaId] Vita Id';
    constructor(public payload: string) {}
}

export class ProviderName {
    public static readonly type = '[providerName] Provider Name';
    constructor(public payload: string) {}
}

export class RefreshRoutineInfo {
    public static readonly type = '[reload routine info] get routine info';
    constructor(public payload: boolean) {}
}

export class ProviderId {
    public static readonly type = '[providerId] ProviderId';
    constructor(public payload: string) {}
}

export class UserEnrollmentDate {
    public static readonly type = 'enrollmentDate';
    constructor(public payload: any) {}
}
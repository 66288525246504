import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { MedexMaterialModule } from "../app-ui.module";
import { TopNavComponent } from "./top-nav/top-nav.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MatStepperModule } from "@angular/material/stepper";
import { PortalModule } from "@angular/cdk/portal";
import { MedexUtilitiesModule } from "../medex-utilities/medex-utilities.module";
import { MedexNetworkConnectionService } from "./medex-network-connection.service";
import {DashboardsRoutingModule} from "./app-commons.routing.module";
import { MedexAppsNavComponent } from "./medex-apps-nav/medex-apps-nav.component";
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@NgModule({
    imports: [
        CommonModule,
        MedexMaterialModule,
        DashboardsRoutingModule,
        MatStepperModule,
        PortalModule,
        MedexUtilitiesModule,
    ],
    declarations: [
        SideNavComponent,
        TopNavComponent,
        DashboardComponent,
        MedexAppsNavComponent,
        BottomNavComponent,
    ],
    exports: [
        SideNavComponent,
        TopNavComponent,
        BottomNavComponent,
    ],
    providers: [
        MedexNetworkConnectionService,
        { provide: MatDialogRef, useValue: {} },
            { provide: MAT_DIALOG_DATA, useValue: {} }
    ]
})
export class AppCommonsModule {
}

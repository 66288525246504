export class MedexUser {
    medexUID: string;           // Unique ID for the user

    appId: string;
    appUserId: string;
    appUserInfo: AppUserInfo;

    // TODO : Add Image URL
}

export class AppUserInfo {
    name?: string;

    firstName: string;
    lastName: string;

    dobInEpoch?: number;
    gender?: string;

    dp?: string;

    heightInCm?: number;
    bmi?: number;

    mobileNumber?: number;

    communication?: AppUserInfoCommunication;
    employment?: AppUserInfoEmployment;
}

export class AppUserInfoCommunication {
    emailId: string;
    phoneNumber: string;
    preferredLanguage: string;
    contactPreference: string;
    alternateNumber1: string;
    alternateNumber2: string;
    alternateNumber3: string;
}

export class AppUserInfoEmployment {
    employeeId: string;
    employerName?: string;
    insuranceHolder?: string;
    category?: string;
    groupId?: string;
    department: string;
    designation: string;
}

export class SourceType {
    static CLIENT = "client";
    static FACILITY = "facility";
    static RESOURCE = "resource";
}

export class MedexPreference {
    preferenceId?: string; // UniqueID in the collection
    // resourceId?: string;
    // appId?: string;
    sourceType?: SourceType;
    sourceId?: string;

    preferenceType?: string;         // CISCO           - ClickToCall
    preferenceInfo?: any;            // CISCO ConnectedAt, ConnectedTo, validUntil   ClickToCall CallFromNumber, numberIsVerified
    preferenceTitle?: string;

    isEnabled?: boolean;
    isVisible?: boolean;
    isEditable?: boolean;
}

export class PreferenceType {
    static APPS = "apps";
    static AHC_REGISTARTION = "registration";
    static APPS_REGISTARTION = "Registration";
    static CONSULT_REGISTRATION = "CONSULT_REGISTRATION";
    static ACCOUNT_INFO = "accountInfo";
    static PRESCRIPTION_TEMPLATE = "TEMPLATES_PRESCRIPTION";

    static MEDICAL_RECORD_SUMMARY = "SUMMARY";
    static MEDICAL_RECORD_READINGS = "MEDICAL_RECORD_READINGS";

    static MEDICAL_RECORD_READINGS_2 = "MEDICAL_RECORD_READINGS_V2";
    static MEDICAL_RECORD_ALERTS = "Alerts";
    static MEDICAL_RECORD_CONNECTED_DEVICES = "MEDICAL_RECORD_CONNECTED_DEVICES";
    static MEDICAL_RECORD_PRINT = "MEDICAL_RECORD_PRINT";
    static MEDICAL_RECORD_LIVE_VITALS = "MEDICAL_RECORD_LIVE_VITALS";

    static MEDICAL_RECORD_ASSESSMENTS = "assessments";
    static MEDICAL_RECORD_ASSESSMENTS_NEWS = "MEDICAL_RECORD_ASSESSMENTS_NEWS";
    static MEDICAL_RECORD_ASSESSMENTS_NEWS_II = "MEDICAL_RECORD_ASSESSMENTS_NEWS_II";
    static MEDICAL_RECORD_ASSESSMENTS_NEWS_II_LOGS = "MEDICAL_RECORD_ASSESSMENTS_NEWS_II_LOGS";
    static MEDICAL_RECORD_ASSESSMENTS_SOFA = "MEDICAL_RECORD_ASSESSMENTS_SOFA";
    static MEDICAL_RECORD_ASSESSMENTS_QSOFA = "MEDICAL_RECORD_ASSESSMENTS_QSOFA";
    static MEDICAL_RECORD_ASSESSMENTS_APACHE_II = "MEDICAL_RECORD_ASSESSMENTS_APACHE_II";
    static MEDICAL_RECORD_ASSESSMENTS_EGFR = "MEDICAL_RECORD_ASSESSMENTS_EGFR";
    static MEDICAL_RECORD_ASSESSMENTS_GCS = "MEDICAL_RECORD_ASSESSMENTS_GCS";



    static MEDICAL_RECORD_NOTES = "MEDICAL_RECORD_NOTES";
    static MEDICAL_RECORD_NOTES_ADVICE = "MEDICAL_RECORD_NOTES_ADVICE";
    static MEDICAL_RECORD_NOTES_TASKS = "MEDICAL_RECORD_NOTES_TASKS";
    static MEDICAL_RECORD_NOTES_DOCUMENTS = "MEDICAL_RECORD_NOTES_DOCUMENTS";
    static MEDICAL_RECORD_NOTES_HEMODYNAMICS = "MEDICAL_RECORD_NOTES_HEMODYNAMICS";
    static MEDICAL_RECORD_NOTES_VENTILATOR = "MEDICAL_RECORD_NOTES_VENTILATOR";
    static MEDICAL_RECORD_NOTES_ARTERIAL_BLOOD_GAS = "MEDICAL_RECORD_NOTES_ARTERIAL_BLOOD_GAS"


    static MEDICAL_RECORD_MEDICATIONS = "MEDICAL_RECORD_MEDICATIONS";
    static MEDICAL_RECORD_INVESTIGATIONS = "MEDICAL_RECORD_INVESTIGATIONS";
    static MEDICAL_RECORD_PAST_HISTORY = "MEDICAL_RECORD_FLUIDS";
    static MEDICAL_RECORD_FLUIDS = "MEDICAL_RECORD_PAST_HISTORY";
    static MEDICAL_RECORD_HEMODYNAMICS = "MEDICAL_RECORD_HEMODYNAMICS";

    static CISCO_VIDEO = "COMMUNICATION_CENTER_CISCO_WEBEX"; // Name is taken from masters json same as preference id
    static MEDEX_VIDEO = "COMMUNICATION_CENTER_MEDEX_VIDEO";
    static AIRTELIQ_TELEPHONY = "COMMUNICATION_CENTER_AIRTELIQ_TELEPHONY";
    static BEDSIDE_MONITOR = "COMMUNICATION_CENTER_BEDSIDE_MONITOR";
    static PTZ_CAMERA = "COMMUNICATION_CENTER_PTZ_CAMERA";

    static BEDSIDE_MONITOR_PUBLISHER = "COMMUNICATION_CENTER_BEDSIDE_MONITOR_PUBLISHER";
    static BEDSIDE_MONITOR_SUBSCRIBER = "COMMUNICATION_CENTER_BEDSIDE_MONITOR_SUBSCRIBER";
    static PTZ_CAMERA_PUBLISHER = "COMMUNICATION_CENTER_PTZ_CAMERA_PUBLISHER";
    static PTZ_CAMERA_SUBSCRIBER = "COMMUNICATION_CENTER_PTZ_CAMERA_SUBSCRIBER";
    static BEDSIDE_MONITOR_VIEW = "COMMUNICATION_CENTER_BEDSIDE_MONITOR_VIEW";


    static QUICK_ADD = "MEDICAL_RECORD_QUICK_ADD";

    static DOCTOR_PROFILE = "DOCTOR_PROFILE";
    static ACCOUNT_RESET_PASSWORD = "ACCOUNT_RESET_PASSWORD";
    static ACCOUNT_MOBILE_NUMBER = "ACCOUNT_MOBILE_NUMBER";
    static AVAILABILITY = "AVAILABILITY";
    static MY_DOCUMENTS = "MY_DOCUMENTS";
    static SUPPORT_EMAIL = "SUPPORT_EMAIL";
    static SUPPORT = "SUPPORT";
    static TEMPLATES_PRESCRIPTION = "TEMPLATES_PRESCRIPTION";
    static TEMPLATES_NOTES = "TEMPLATES_NOTES";
    static TEMPLATES_TASKS = "TEMPLATES_TASKS";
    static MASTERS_MEDICATIONS = "MASTERS_MEDICATIONS";
    static MASTERS_INVESTIGATIONS = "MASTERS_INVESTIGATIONS";
    static MASTERS_IV_FLUIDS = "MASTERS_IV_FLUIDS";
    static CHARTS = "CHARTS";
    static CHARTS_ENCOUNTERS = "CHARTS_ENCOUNTERS";
    static TAGS = "TAGS";
    static ALERTS = "Alerts";
    static FAVOURITES_MEDICATION = "FAVOURITES_MEDICATION";
    static FAVOURITES = "FAVOURITES";
    static COMMUNICATION_CENTER = "COMMUNICATION_CENTER";
    static NOTIFICATION_VIDEO_CALL = "NOTIFICATION_VIDEO_CALL";
    static NOTIFICATION_NEW_APPOINTMENT = "NOTIFICATION_NEW_APPOINTMENT";

    static PATIENT_ACTION_CENTER = "PATIENT_ACTION_CENTER";
    static MEDMANTRA_REGION = "MEDMANTRA_REGION";
    static RATE_CARDS = "RATE_CARDS"
    static AHC_PATIENT_ADD_PATIENT = "AHC_PATIENT_ADD_PATIENT";
    static AHC_PATIENT_EDIT_PATIENT = "AHC_PATIENT_EDIT_PATIENT";
    static AHC_PATIENT_DASHBOARD = "AHC_PATIENT_DASHBOARD";
    static AHC_PATIENT_ADD_BOOKING = "AHC_PATIENT_ADD_BOOKING";

    static ENCOUNTERS_SERVICE_TYPES = "ENCOUNTERS_SERVICE_TYPES";
    static EMR_CUSTOM_COMPONENTS = "EMR_CUSTOM_COMPONENTS";
    static EMR_COMPONENTS = "EMR_COMPONENTS";
    static USER_PRISM_INTEGRATION = "USER_PRISM_INTEGRATION";
    static DISCHARGE_SCOPES = "DISCHARGE_SCOPES";
    static ESCALATION_TEAM = "ESCALATION_TEAM";

    static PROJECT_LOGOS = "PROJECT_LOGOS";
}


export class AppScopes {
    static MY_ADMISSIONS = "my_admissions";
    static TELANGANA_TELE_ICU = "book:telanganaicu";
    static MANAGE_DEVICE = "manageDevice";
    static ALERTS = "alerts";
    static FACILITIES = "facilities";
    static INVESTIGATIONS = "investigations";
    static TASKS = "tasks";
    static DISCHARGES = "discharges";
    static CHARTS = "charts";
    static SETTINGS = "settings";
    static MY_APPOINTMENTS = "my_appointments";
    static PATIENT_REGISTRATION = "patient_registration";
    static MY_PATIENTS = "my_patients";
    static NOTIFICATIONS = "notifications";
    static ALL_APPOINTMENTS = "all_appointments";
    static ADMISSIONS = "admissions";
    static BOOKING_REQUESTS = "booking_requests";
    static TRANSACTIONS = "transactions";
    static AVAILABILITY = "availability";
    static LIVE_DOCTOR_STATUS = "live_doctor_status";
    static DOCTORS = "doctors";
    static PROJECTS = "projects";
    static REFERRALS = "referrals";
    static INSIGHTS = "insights";
    static AUDITOR = "auditor";
    static FACILITY_APPINTMENTS = "facility_appointments";
    static RESOURCES = "resources";
}

export class ChannelType {
    static SMS = "SMS";
    static EMAIL = "EMAIL";
    static WHATSAPP = "WHATSAPP";
    static PUSH_NOTIFICATIONS = "PUSH_NOTIFICATIONS";
    static IN_APP_NOTIFICATIONS = "IN_APP_NOTIFICATIONS";
}
export class CommunicationChannel {
    channelName: string;
    channelType: ChannelType;
    isEnabled: boolean;
}

export const COMMUNICATION_CHANNELS: CommunicationChannel[] = [
    {
        channelName: "Sms",
        channelType: ChannelType.SMS,
        isEnabled: true
    },
    {
        channelName: "Email",
        channelType: ChannelType.EMAIL,
        isEnabled: true
    },
    {
        channelName: "Whatsapp",
        channelType: ChannelType.WHATSAPP,
        isEnabled: true
    },
    {
        channelName: "Push Notifications",
        channelType: ChannelType.PUSH_NOTIFICATIONS,
        isEnabled: true
    },
    {
        channelName: "In App Notifications",
        channelType: ChannelType.IN_APP_NOTIFICATIONS,
        isEnabled: true
    }
]